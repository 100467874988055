<template>
	<div>
		<el-card>
			<el-row :gutter="20">
				<el-col :span="6">
					<el-input placeholder="请输入门店名称" v-model="queryInfo.query" clearable @clear="getStore">
						<el-button slot="append" icon="el-icon-search" @click="getStore"></el-button>
					</el-input>
				</el-col>
				<el-col :span="3">
					<el-button type="primary" icon="el-icon-plus" @click="addStore">新增门店</el-button>
				</el-col>
			</el-row>
			<!-- 服务列表 -->
			<el-table :data="storeList" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="store_id" label="ID" width="100px"></el-table-column>
				<el-table-column label="封面图">
					<template slot-scope="scope">
						<div>
							<el-avatar shape="square" size="large" :src="scope.row.store_logo"></el-avatar>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="store_name" label="门店名称" :show-overflow-tooltip="true" width="180px"></el-table-column>
				<el-table-column prop="store_phone" label="门店电话" width="120px"></el-table-column>
				<el-table-column label="上班时间" width="120px">
					<template slot-scope="scope">
						<div>{{scope.row.store_start}}-{{scope.row.store_end}}</div>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" prop="address" label="所处地址" width="200px"></el-table-column>
				<el-table-column label="是否停用">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.is_close" @change="changeState(scope.row)">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column prop="add_time" label="创建时间" width="150px"></el-table-column>
				<el-table-column fixed="right" label="操作" width="180px">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" plain  @click="editStore(scope.row.store_id)">编辑</el-button>
						<el-button size="mini" type="warning" plain @click="changeBz(scope.row)">分佣标准</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
		
			<el-dialog title="修改分佣标准" width="36%" :visible.sync="bzVisible">
				<el-form label-width="80px">
					<el-form-item label="分佣标准:" style="margin-bottom: 20px;">
						<el-select v-model="biaozhun_id" placeholder="请选择">
					    <el-option
					      v-for="item in biaozhun_list"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					  </el-select>
					</el-form-item>
					<el-button type="default" @click="bzVisible=false">取消</el-button>
					<el-button type="primary" @click="subBzEdit">确认</el-button>
				</el-form>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	let that;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		data() {
			return {
				total: 0,
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				storeList: [],
				//分佣标准
				bzVisible: false,
				relation_id: '',
				biaozhun_id: '',
				biaozhun_list: [],
			};
		},
		components: {
			Pagination
		},
		created() {
			if (!this.$store.state.userInfo) {
		        // 防止未登录
		        this.$router.push({path: '/login'})
		    }else{
		    	that = this;
				this.getStore();
			}
		},
		methods: {
			getStore() {
				var url = 'store/store_list';
				let params = {
					store_name: this.queryInfo.query,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.storeList = res.list;
						that.biaozhun_list = res.biaozhun_list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//新增门店
			addStore() {
				this.$router.push('/store_list/store_add')
			},
			//编辑
			editStore(id) {
				this.$router.push({
					path: '/store_list/store_add',
					query: {
						id
					}
				})
			},
			//删除
			changeState(item) {
				var url = 'store/delete_store';
				let params = {
					store_id: item.store_id,
				};
				that.fd_post(url, params).then((res) => {
					if(res.status) {
						that.$message.success("操作成功");
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getStore();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getStore();
			},
			//分佣标准
			changeBz(item){
				this.relation_id = item.store_id;
				this.biaozhun_id = item.biaozhun_id;
				this.bzVisible = true;
			},
			subBzEdit(){
				var url = 'biaozhun/relation';
		        let params = {
		        	biaozhun_id: this.biaozhun_id,
		        	relation_id: this.relation_id,
		        	relation_type: 2,
		        };
		        this.fd_post(url, params).then((res) => {
	        		if(res.status){
	        			this.bzVisible = false;
	        			this.$message.success("操作成功");
	        			this.getStore();
	        		}else{
	        			this.$message.error(res.msg);
	        		}
		        }).catch((err) => {
		            this.$message.error('网络错误');
		        });
			},
		},
	};
</script>

<style lang="scss" scoped>
	.el-table{
	  margin-top: 20px;
	}
</style>